import React from "react";
import {Box, ThemeContext} from "grommet";
import {normalizeColor} from "grommet/utils";
import { FontAwesomeIcon as FAIcon } from '@fortawesome/react-fontawesome'
import {
    faCircle as IconCircle
} from '@fortawesome/pro-solid-svg-icons'
import {
    ICON_WIDTH_DEFAULT,
    ICON_WIDTH_LARGE,
    ICON_WIDTH_MEDIUM,
    ICON_WIDTH_SMALL,
    ICON_WIDTH_XLARGE,
    ICON_WIDTH_XSMALL,
    ICON_WIDTH_XXLARGE,
    ICON_WIDTH_XXSMALL,
    ICON_WIDTH_XXXSMALL
} from "../constants/Dimensions";
import ConditionalWrapper from "./ConditionalWrapper";

const FontAwesomeIcon = ({
    SelectedIcon,
    size = 'medium',
    customSize,
    color="text",
    bold=false,
    // Forces width == height
    fixedWidth=true,
    highlight,
    highlightProps={},
    rotate,
    border,
    iconBorderColor,
    styleProps,
    iconContainerProps,
    additionalClass='',
    ...props
}) => {
    let size_px;
    switch (size) {
    case 'xxxsmall':
        size_px = ICON_WIDTH_XXXSMALL;
        break;
    case 'xxsmall':
        size_px = ICON_WIDTH_XXSMALL;
        break;
    case 'xsmall':
        size_px = ICON_WIDTH_XSMALL;
        break;
    case 'small':
        size_px = ICON_WIDTH_SMALL;
        break;
    case 'medium':
        size_px = ICON_WIDTH_MEDIUM;
        break;
    case 'large':
        size_px = ICON_WIDTH_LARGE;
        break;
    case 'xlarge':
        size_px = ICON_WIDTH_XLARGE;
        break;
    case 'xxlarge':
        size_px = ICON_WIDTH_XXLARGE;
        break;
    default:
        size_px = ICON_WIDTH_DEFAULT;
        break;
    }
    if (customSize) size_px = customSize;
    return (
        <ThemeContext.Consumer>
            { (theme) =>(
                <Box {...iconContainerProps}>
                    <ConditionalWrapper
                        condition={!!highlight}
                        wrapperIfFalse={children => <>{children}</>}
                        wrapperIfTrue={children => (
                            <Box margin={{vertical: 'xsmall'}} width={`${size_px}px`} height={`${size_px}px`} className={`fa-layers fa-fw ${additionalClass || ''}`} {...highlightProps}>
                                <FAIcon
                                    style={{height: size_px, width: size_px, ...styleProps, ...highlightProps}}
                                    icon={IconCircle}
                                    color={normalizeColor(theme.global.colors[highlight], theme)}
                                />
                                {children}
                            </Box>
                        )}
                    >
                        <FAIcon
                            icon={SelectedIcon}
                            style={{height: size_px, width: size_px, transition: '.15s',...styleProps}}
                            fixedWidth={fixedWidth}
                            transform={highlight ? 'shrink-3' : ''}
                            color={normalizeColor(theme.global.colors[color], theme)}
                            {...props}
                        />
                    </ConditionalWrapper>
                </Box>
            )}
        </ThemeContext.Consumer>
    );
};

export default FontAwesomeIcon;
